/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable fp/no-let */
import * as R from 'ramda'
import {arrayOf, shape, string} from 'prop-types'
import React, {useEffect, useState} from 'react'

import {jobRequestOption, requestUrl, sortList} from 'helpers/teamtailorModule'
import useTeamTailorList from 'hooks/useTeamTailorList'

import JobsList from './JobsList'
import Selectors from './Selectors'

const TeamTailorModule = ({companies, selectorsData}) => {
  const [departmentList, setDepartmentList] = useState([])
  const [locationList, setLocationList] = useState([])

  const [currentLocationId, setCurrentLocationId] = useState()
  const [currentRegionId, setCurrentRegionId] = useState()
  const [currentDepartmentId, setCurrentDepartmentId] = useState()

  const [currentLocationQuery, setCurrentLocationQuery] = useState('')
  const [currentRegionQuery, setCurrentRegionQuery] = useState('')
  const [currentDepartmentQuery, setCurrentDepartmentQuery] = useState('')
  const [currentCompany, setCurrentCompany] = useState('all')

  const [jobsLocation, setjobsLocation] = useState([])
  const [jobsDepartment, setjobsDepartment] = useState([])

  const [loading, setLoading] = useState(true)
  const [jobList, setJobList] = useState([])

  const [selectedCompany, setSelectedCompany] = useState(companies)

  const [sortedJobList, setSortedJobList] = useState()

  const [newDepartmentList] = useTeamTailorList(departmentList, 'name')
  const [newLocationList] = useTeamTailorList(locationList, 'city')
  const [newCountryList] = useTeamTailorList(locationList, 'country')

  const jobRequestUrl = `${requestUrl}jobs?${currentLocationQuery}${currentRegionQuery}${currentDepartmentQuery}page%5Bsize%5D=30`

  const requestAllJobs = hook => {
    setLoading(true)

    R.map(
      company =>
        fetch(jobRequestUrl, jobRequestOption(company.teamTailorApiKey))
          .then(response => response.json())
          .then(response => {
            R.map(el => (el.company = company.name), response.data)
            hook(oldArray => R.flatten([...oldArray, response.data]))
          })
          .then(() => {
            setTimeout(() => {
              setLoading(false)
            }, 1000)
          }),
      selectedCompany,
    )
  }

  const requestFilteredJobs = hook => {
    setLoading(true)

    R.map(
      company =>
        fetch(jobRequestUrl, jobRequestOption(company.teamTailorApiKey))
          .then(response => response.json())
          .then(response => {
            R.map(el => (el.company = company.name), response.data)
            hook(oldArray => R.flatten([...oldArray, response.data]))
          })
          .then(() => {
            setTimeout(() => {
              setLoading(false)
            }, 1000)
          }),
      selectedCompany,
    )
  }

  const requestJobs = async hook => {
    if (currentCompany === 'all') {
      requestAllJobs(hook)
    } else {
      requestFilteredJobs(hook)
    }
  }

  const requestDepartments = async hook => {
    R.map(
      company =>
        fetch(
          `${requestUrl}departments`,
          jobRequestOption(company.teamTailorApiKey),
        )
          .then(response => response.json())
          .then(response =>
            hook(oldArray => R.flatten([...oldArray, response.data])),
          ),
      companies,
    )
  }

  const requestLocations = async hook => {
    R.map(
      company =>
        fetch(
          `${requestUrl}locations`,
          jobRequestOption(company.teamTailorApiKey),
        )
          .then(response => response.json())
          .then(response =>
            hook(oldArray => R.flatten([...oldArray, response.data])),
          ),
      companies,
    )
  }

  const handleJobsLocation = async (url, job) => {
    const getTeamTailorApiKey = R.filter(
      company => company.name === job.company,
      selectedCompany,
    )

    const jobApiKey = R.pathOr('', [0, 'teamTailorApiKey'], getTeamTailorApiKey)

    let loc
    let id

    if (R.length(jobApiKey) !== 0) {
      await fetch(url, jobRequestOption(jobApiKey))
        .then(response => response.json())
        // eslint-disable-next-line no-return-assign
        .then(
          response => (
            (loc = R.pathOr('', ['data', 'attributes', 'city'], response)),
            ({id} = job)
          ),
        )
    }

    setjobsLocation(oldArray => [...oldArray, {id, loc}])

    return loc
  }

  const handleJobsDepartment = async (url, job) => {
    const getTeamTailorApiKey = R.filter(
      company => company.name === job.company,
      selectedCompany,
    )

    const jobApiKey = R.pathOr('', [0, 'teamTailorApiKey'], getTeamTailorApiKey)

    let department
    let id

    if (R.length(jobApiKey) !== 0) {
      await fetch(url, jobRequestOption(jobApiKey))
        .then(response => response.json())
        // eslint-disable-next-line no-return-assign
        .then(
          // eslint-disable-next-line no-return-assign
          response => (
            (department = R.pathOr(
              ' ',
              ['data', 'attributes', 'name'],
              response,
            )),
            ({id} = job)
          ),
        )
    }

    setjobsDepartment(oldArray => [...oldArray, {id, department}])

    return department
  }

  const handleSelectedCompany = name => {
    if (name !== 'all') {
      setCurrentCompany(name)
    } else {
      setCurrentCompany('all')
    }
  }
  const handleLocationId = id => {
    setCurrentLocationId(id)
    if (id !== 'all') {
      setCurrentLocationQuery(`filter%5Blocations%5D=${id}&`)
    } else {
      setCurrentLocationQuery('')
    }
  }
  const handleRegionId = id => {
    setCurrentRegionId(id)
    if (id !== 'all') {
      setCurrentRegionQuery(`filter%5Bregions%5D=${id}&`)
    } else {
      setCurrentRegionQuery('')
    }
  }
  const handleDepartmentId = id => {
    setCurrentDepartmentId(id)
    if (id !== 'all') {
      setCurrentDepartmentQuery(`filter%5Bdepartment%5D=${id}&`)
    } else {
      setCurrentDepartmentQuery('')
    }
  }

  const functionToFilterCompany = currentComp => {
    const filteredCompanies = R.filter(
      company => company.name === currentComp,
      companies,
    )

    return currentComp === 'all' ? companies : filteredCompanies
  }

  useEffect(() => {
    requestDepartments(setDepartmentList)
    requestLocations(setLocationList)
  }, [])

  useEffect(() => {
    if (jobList) {
      R.map(job => {
        const loc = job.relationships.location.links.related

        return handleJobsLocation(loc, job)
      }, jobList)

      R.map(job => {
        const department = job.relationships.department.links.related

        return handleJobsDepartment(department, job)
      }, jobList)
    }
  }, [jobList])

  useEffect(() => {
    setJobList([])
    requestJobs(setJobList)
  }, [selectedCompany, currentLocationId, currentRegionId, currentDepartmentId])

  useEffect(() => {
    const filteredCompanies = functionToFilterCompany(currentCompany)

    return setSelectedCompany(filteredCompanies)
  }, [currentCompany])

  useEffect(() => {
    const sortJobList = sortList(jobList, 'created-at')

    return setSortedJobList(sortJobList)
  }, [jobList])

  return (
    <>
      <Selectors
        handleDepartmentId={handleDepartmentId}
        departmentList={newDepartmentList}
        handleRegionId={handleRegionId}
        regionList={newCountryList}
        handleLocationId={handleLocationId}
        locationList={newLocationList}
        handleSelectedCompany={handleSelectedCompany}
        companiesList={companies}
        loading={loading}
        selectorsData={selectorsData}
      />
      {sortedJobList && (
        <JobsList
          jobList={sortedJobList}
          jobsDepartment={jobsDepartment}
          jobsLocation={jobsLocation}
          loading={loading}
        />
      )}
    </>
  )
}

TeamTailorModule.propTypes = {
  companies: arrayOf(
    shape({
      name: string,
      teamTailorApiKey: string,
    }),
  ),
}

TeamTailorModule.defaultProps = {
  companies: [],
}

export default TeamTailorModule
