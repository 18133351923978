import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing, fontSizes, breakpoints}) => ({
  cardContent: {
    padding: spacing(0),
  },

  contentContainer: {
    width: '100%',
    minHeight: 50,
  },

  description: {
    fontFamily: 'CamptonLight',
    padding: spacing(0, 2),
    textAlign: 'center',
    fontSize: fontSizes.description,
  },

  image: {
    objectFit: 'cover',
    transition: '.5s ease all',
    width: '100%',
  },
  imageContainer: {
    minHeight: 230,
    maxHeight: 230,
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      content: '',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: '.5s ease all',
      width: '100%',
    },
    '&:hover': {
      '& $image': {
        transform: 'scale(1.1)',
      },
    },
  },

  imageContainerNoImage: {
    backgroundImage: 'url(/android-chrome-512x512.png)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: 230,
  },

  jobCard: {
    margin: spacing(2.5, 'auto'),
    minHeight: 420,
    minWidth: 340,
    '& img': {
      height: '100%',
      minHeight: 230,
      minWidth: 340,
      width: '100%',
    },
    [breakpoints.down('sm')]: {
      margin: spacing(2, 0),
      width: '100%',
    },
    [breakpoints.down('md')]: {
      margin: spacing(1.5),
    },
  },

  loadingDiv: {
    background: palette.background.greyLight,
    width: 308,
    height: 52,
    borderRadius: 8,
    transform: 'translateZ(0)',
    margin: 'auto',
  },

  remoteIcon: {
    color: 'black',
  },

  root: {
    borderRadius: spacing(1),
    transform: 'translateZ(0)',
    height: '100%',
    overflow: 'hidden',
    minHeight: 400,
    padding: 0,
    position: 'relative',
    '&::after': {
      content: '',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: '.5s ease all',
      width: '100%',
    },
    '&:hover': {
      '& $image': {
        transform: 'scale(1.1)',
      },
    },
    [breakpoints.up('md')]: {
      maxWidth: 340,
    },
  },

  title: {
    color: palette.primary.main,
    fontFamily: 'CamptonLight',
    padding: spacing(2),
    textAlign: 'center',
    fontSize: fontSizes.innerTitle,
  },
}))

export default useStyles
