/* eslint-disable no-nested-ternary */
import * as R from 'ramda'

export const requestUrl = 'https://api.teamtailor.com/v1/'

export const jobRequestOption = apiKey => ({
  method: 'GET',
  headers: {
    Authorization: `Token token=${apiKey}`,
    'X-Api-Version': '20210218',
  },
})

export const sortList = (listArray, attribute) =>
  R.sort(
    (a, b) =>
      a.attributes[attribute] > b.attributes[attribute]
        ? -1
        : a.attributes[attribute] < b.attributes[attribute]
        ? 1
        : 0,
    listArray,
  )
