// loader component
import {useTheme} from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

const Loader = ({isWhite}) => {
  const theme = useTheme()

  return (
    <CircularProgress
      style={{
        display: 'flex',
        margin: 'auto',
        color: isWhite ? 'white' : theme.palette.primary.main,
      }}
    />
  )
}

export default Loader
