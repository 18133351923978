import * as R from 'ramda'
import {arrayOf, objectOf, shape, string} from 'prop-types'
import {upperFirst} from 'lodash'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import React from 'react'
import Typography from '@mui/material/Typography'
import WifiIcon from '@mui/icons-material/Wifi'

import {removeDuplicateObject} from 'helpers/utils'

import useStyles from './styles'

const JobCard = ({job, jobsDepartment, jobsLocation}) => {
  const classes = useStyles()

  const jobCareerSiteUrl = R.pathOr(' ', ['links', 'careersite-job-url'], job)
  const jobPicture = R.pathOr(null, ['attributes', 'picture'], job)
  const jobThumbnail = R.pathOr(' ', ['attributes', 'picture', 'thumb'], job)
  const jobTitle = R.pathOr(' ', ['attributes', 'title'], job)
  const jobRemoteStatus = R.pathOr(' ', ['attributes', 'remote-status'], job)
  const jobRemoteStatusField = `${upperFirst(jobRemoteStatus)} Remote `
  const hasJobRemoteStatus = jobRemoteStatus !== 'none'

  const renderAttribute = (array, attribute, hasDot) => {
    const getAttribute =
      array &&
      R.pathOr(
        ' ',
        [0, attribute],
        removeDuplicateObject(
          R.filter(jobAttr => jobAttr.id === job.id, array),
        ),
      )

    const jobHasAttribute = R.pipe(R.trim(), R.length())(getAttribute) !== 0

    return jobHasAttribute ? (
      <span>{`${getAttribute} ${hasDot ? '·' : ''} `}</span>
    ) : null
  }

  const JobRemoteStatus = () => (
    <span>
      {renderAttribute(jobsDepartment, 'department', true) !== null &&
        renderAttribute(jobsLocation, 'loc') !== null && <span> · </span>}
      {jobRemoteStatusField}
      <span className={classes.remoteIcon}>
        <WifiIcon />
      </span>
    </span>
  )

  return (
    <>
      <div className={classes.jobCard}>
        <a rel="noreferrer" href={jobCareerSiteUrl}>
          <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
              <div
                className={
                  jobPicture
                    ? classes.imageContainer
                    : classes.imageContainerNoImage
                }
              >
                {jobPicture && (
                  <img className={classes.image} src={jobThumbnail} alt="" />
                )}
              </div>

              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {jobTitle}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.description}
              >
                <div className={classes.contentContainer}>
                  <>
                    {renderAttribute(jobsDepartment, 'department', true)}
                    {renderAttribute(jobsLocation, 'loc')}
                    {hasJobRemoteStatus && <JobRemoteStatus />}
                  </>
                </div>
              </Typography>
            </CardContent>
          </Card>
        </a>
      </div>
    </>
  )
}

JobCard.propTypes = {
  job: objectOf(),
  jobsDepartment: arrayOf(shape({id: string, department: string})),
  jobsLocation: arrayOf(shape({id: string, loc: string})),
}
JobCard.defaultProps = {
  job: {},
  jobsDepartment: [],
  jobsLocation: [],
}

export default JobCard
