/* eslint-disable fp/no-mutating-methods */
import * as R from 'ramda'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'

import useStyles from './styles'

const Selectors = ({
  companiesList,
  departmentList,
  handleDepartmentId,
  handleLocationId,
  handleSelectedCompany,
  locationList,
  loading,
  selectorsData,
}) => {
  const classes = useStyles()

  const renderSelectorOptions = (array, key) =>
    array &&
    R.map(
      department => (
        <option key={department.id} value={department.id}>
          {department[`${key}`]}
        </option>
      ),
      array,
    )

  const renderLocationSelectorOptions = (array, key) =>
    array &&
    R.map(
      department =>
        department[`${key}`] ? (
          <option key={department.id} value={department.id}>
            {department[`${key || 'name'}`]}
          </option>
        ) : (
          <option value={department.id}>{department[`${key}`]}</option>
        ),
      array,
    )

  const {
    defaultCompaniesFilterLabel,
    defaultDepartmentsFilterLabel,
    defaultCountriesFilterLabel,
  } = selectorsData

  return (
    <div className={classes.root}>
      <div className={classes.selectContainer}>
        <div className={classes.container}>
          <select
            className={classes.select}
            name="companies"
            id="companies"
            onChange={e => handleSelectedCompany(e.target.value)}
            disabled={loading}
          >
            <option value="all">{defaultCompaniesFilterLabel}</option>
            {companiesList &&
              R.map(company => {
                const companyName = R.pathOr('', ['name'], company)

                return (
                  <option key={company.id} value={companyName}>
                    {companyName}
                  </option>
                )
              }, companiesList)}
          </select>
          <span className={classes.expandMoreIcon}>
            <ExpandMoreIcon />
          </span>
        </div>
        <div className={classes.container}>
          <select
            className={classes.select}
            name="departments"
            id="departments"
            onChange={e => handleDepartmentId(e.target.value)}
            disabled={loading}
          >
            <option value="all">{defaultDepartmentsFilterLabel}</option>
            {renderSelectorOptions(departmentList, 'name')}
          </select>
          <span className={classes.expandMoreIcon}>
            <ExpandMoreIcon />
          </span>
        </div>
        <div className={classes.container}>
          <select
            className={classes.select}
            name="location"
            id="location"
            onChange={e => handleLocationId(e.target.value)}
            disabled={loading}
          >
            <option value="all">{defaultCountriesFilterLabel}</option>
            {renderLocationSelectorOptions(locationList, 'name')}
          </select>
          <span className={classes.expandMoreIcon}>
            <ExpandMoreIcon />
          </span>
        </div>
      </div>
    </div>
  )
}

Selectors.propTypes = {
  companiesList: arrayOf(
    shape({
      name: string,
      teamTailorApiKey: string,
    }),
  ),
  departmentList: arrayOf(
    shape({
      id: arrayOf(string),
      name: string,
    }),
  ),
  handleDepartmentId: func,
  handleLocationId: func,

  handleSelectedCompany: func,
  loading: bool,
  locationList: arrayOf(
    shape({
      id: arrayOf(string),
      name: string,
    }),
  ),
}

Selectors.defaultProps = {
  companiesList: [],
  departmentList: [],
  handleDepartmentId: () => {},
  handleLocationId: () => {},
  handleSelectedCompany: () => {},
  loading: true,
  locationList: [],
}

export default Selectors
