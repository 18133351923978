import * as R from 'ramda'
import {arrayOf, bool, shape, string} from 'prop-types'
import React from 'react'

import Loader from 'components/UI/Loader'

import JobCard from './JobCard'
import useStyles from './styles'

const JobList = ({jobList, jobsDepartment, jobsLocation, loading}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const renderJobList = () => (
    <>
      {jobList &&
        mapIndexed(
          (job, index) => (
            <JobCard
              key={index}
              job={job}
              jobsDepartment={jobsDepartment}
              jobsLocation={jobsLocation}
            />
          ),
          jobList,
        )}
    </>
  )

  return (
    <>
      <div className={classes.container}>
        {loading && (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
        {jobList.length === 0 && !loading && (
          <div className={classes.noResultsMessage}>
            <h2>No jobs found</h2> <p>Your search does not match any job.</p>
          </div>
        )}
        {!loading && jobList.length !== 0 && (
          <div className={classes.row}>{renderJobList()}</div>
        )}
      </div>
    </>
  )
}

JobList.propTypes = {
  jobList: arrayOf(),
  jobsDepartment: arrayOf(shape({id: string, department: string})),
  jobsLocation: arrayOf(shape({id: string, loc: string})),
  loading: bool,
}
JobList.defaultProps = {
  jobList: [],
  jobsDepartment: [],
  jobsLocation: [],
  loading: true,
}

export default JobList
