/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-mutating-methods */
import * as R from 'ramda'
import {useEffect, useState} from 'react'

const prettifyObjectList = (arr, key) => {
  const array = []

  R.map(
    department =>
      array.push({id: [department.id], name: department.attributes[key]}),
    arr,
  )

  return array
}

const removeDuplicate = arr => Array.from(new Set(arr))

const filterByName = arr =>
  arr.filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)

const sortByName = arr => arr.sort((a, b) => a.name.localeCompare(b.name))

const mergeArrayOfDuplicates = arr => {
  const mergedArray = []

  arr.forEach(function (a) {
    if (!this[a.name]) {
      this[a.name] = {id: [a.id], name: a.name}
      mergedArray.push(this[a.name])
    }
    this[a.name].id.push(a.id)
    this[a.name].id = this[a.name].id.flat()
    this[a.name].id = removeDuplicate(this[a.name].id)
  }, Object.create(null))

  return mergedArray
}

const reorganizeListData = array => {
  const duplicateFilteredData = filterByName(array)

  const elementsByNameCountList = array.reduce((p, c) => {
    p[c.name] = (p[c.name] || 0) + 1

    return p
  }, {})

  const arrayOfDuplicates = array.filter(
    obj => elementsByNameCountList[obj.name] > 1,
  )

  const mergedArrayOfDuplicates = mergeArrayOfDuplicates(arrayOfDuplicates)

  const concatedResults = filterByName(
    mergedArrayOfDuplicates.concat(duplicateFilteredData),
  )

  const sortedResults = sortByName(concatedResults)

  return sortedResults
}

export default function useTeamTailorList(listArray, key) {
  const [state, setState] = useState([])

  useEffect(
    () => () => {
      const newArray = prettifyObjectList(listArray, key)

      const reorganizedListData = reorganizeListData(newArray)

      setState(reorganizedListData)
    },
    [listArray],
  )

  return [state, setState]
}
