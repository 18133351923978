/* eslint-disable fp/no-let */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import * as R from 'ramda'
import {arrayOf, bool, shape, string} from 'prop-types'
import {graphql} from 'gatsby'
import {pageContextPropTypes} from 'helpers/propTypes'
import React from 'react'

import RawText from 'components/UI/RawText'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import TeamTailorModule from 'components/JoinUs/TeamTailorModule'
import Title from 'components/UI/Title'

const JobsPage = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    metaTitle,
    metaDescription,
    jobListTitle,
    companies: {companies},
    defaultCompaniesFilterLabel,
    defaultDepartmentsFilterLabel,
    defaultCountriesFilterLabel,
  } = R.pathOr(' ', ['contentfulJobs'], data)

  const {fourthSection, fourthSectionTitle} = R.pathOr(
    ' ',
    ['contentfulJoinUs'],
    data,
  )

  const selectorsData = {
    defaultCompaniesFilterLabel,
    defaultDepartmentsFilterLabel,
    defaultCountriesFilterLabel,
  }

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasSmallPadding>
        <Title isCentered>{jobListTitle}</Title>
        <TeamTailorModule companies={companies} selectorsData={selectorsData} />
      </Section>
      <Section hasGreyBackground hasSmallPadding hasSmallWidth>
        <Title type="title" variant="h2" isCentered>
          {fourthSectionTitle}
        </Title>
        <RawText text={fourthSection} richTextDocuments={richTextDocuments} />
      </Section>
    </>
  )
}

JobsPage.propTypes = {
  data: shape({
    contentfulJobs: shape({
      slug: string,
      metaTitle: string,
      metaDescription: string,
      jobListTitle: string,
      companies: shape({
        companies: arrayOf(
          shape({
            name: string,
            teamTailorApiKey: string,
          }),
        ),
      }),
    }),
    contentfulCountry: shape({
      countryCode: string,
      navLocale: string,
      urlPrefix: string,
      isMultilang: bool,
      technicalName: string,
    }),
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

JobsPage.defaultProps = {}

export default JobsPage

export const pageQuery = graphql`
  query templateJobsPage(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulJobs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      metaTitle
      metaDescription
      jobListTitle
      slug
      companies {
        companies {
          name
          teamTailorApiKey
        }
      }
      defaultCompaniesFilterLabel
      defaultDepartmentsFilterLabel
      defaultCountriesFilterLabel
    }
    contentfulJoinUs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      fourthSectionTitle
      fourthSection {
        raw
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`
