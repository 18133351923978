import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing, fontSizes}) => ({
  container: {
    display: 'inline',
    position: 'relative',
  },

  expandMoreIcon: {
    position: 'absolute',
    top: -5,
    right: 15,
    pointerEvents: 'none',
  },

  root: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    borderBottom: `1px solid ${palette.background.gray}`,
    marginBottom: spacing(2),
  },

  select: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'CamptonMedium',
    '-webkit-appearance': 'none',
    margin: spacing(3, 2, 3, 0),
    outline: 'none',
    borderRadius: 10,
    transform: 'translateZ(0)',
    fontSize: fontSizes.description,
    padding: 8,
    minWidth: 250,
    maxWidth: 250,
    paddingRight: spacing(4),
    width: 'fit-content',
    '&:after': {
      borderLeft: '2px solid #0ebeff',
      content: '\f078',
      color: '#0ebeff',
      height: 34,
      padding: '15px 0px 0px 8px',
      position: 'absolute',
      right: 11,
      top: 6,
    },
    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 1px black',
    },
    '&:hover': {
      background: 'transparent',
      backgroundColor: palette.background.greyLight,
    },
    [breakpoints.down('sm')]: {
      margin: spacing(0, 2),
    },
  },

  selectContainer: {
    marginLeft: 'auto',
    [breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}))

export default useStyles
