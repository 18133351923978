import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  container: {
    margin: spacing(0, 'auto'),
    maxWidth: 1280,
    minHeight: 1000,
    minWidth: 320,
  },

  loaderContainer: {
    marginTop: spacing(6),
  },

  noResultsMessage: {
    margin: 'auto',
    marginTop: spacing(6),
    textAlign: 'center',
  },

  row: {
    display: 'flex',
    flexWrap: 'wrap',
    [breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}))

export default useStyles
